// src/components/CheckoutForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Api from "../../api/api";
import apiUtil from '../../api/apiUtil';
import axios from 'axios';

const CheckoutForm = ({ cartItems }) => {
  console.log(cartItems)
  const stripe = useStripe();
  const elements = useElements();

  // Shipping-related states
  const [shippingCost, setShippingCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [shippingId, setShippingId] = useState(null);
  
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  // const [selectedMethod, setSelectedMethod] = useState('');

  const [selectedOption, setSelectedOption] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [store, setStore] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [validationError, setValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [couponCode, setCouponCode] = useState('');
  const [couponAmount, setCouponAmount] = useState(0);
  const [couponError, setCouponError] = useState('');
  const [showCoupon, setShowCoupon] = useState(false);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  useEffect(() => {
    fetchShippingMethods();
    calculateTotalPrice();
  }, [shippingCost, couponAmount]);

  const calculateTotalPrice = () => {
    const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalPrice(subtotal + shippingCost - couponAmount);
  };

  // Fetch shipping methods from the API
  const fetchShippingMethods = useCallback(() => {
    setIsLoading(true);
    const getData = Api.read('shipping_methods', {});
    getData.then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          const dataArr = data.data && data.data.length > 0 ? data.data : [];
          const transformedData = dataArr.map((method) => ({
            title: getDisplayName(method.method),
            method: method.method,
            cost: method.cost,
          }));

          const defaultVirtualEmail = {
            title: 'Virtual Shipping',
            method: 'virtual',
            cost: 0,
          };

          setShippingMethods([defaultVirtualEmail, ...transformedData]);

          const shipArr = data.ship && data.ship.length > 0 ? data.ship : [];

          const transformedShip = shipArr.map((method) => ({
            title: method.region,
            method: method.method,
            cost: method.cost,
            region: method.region,
            shippingType: method.shipping_type
          }));
          console.log(transformedShip)
          // Set the regions
          setRegions(transformedShip);
          setIsLoading(false);

        },
        (error) => {
          setShippingMethods([]);
          setIsLoading(false);
          console.error('Error fetching shipping methods:', error);
        }
      );
    });
  }, []);

  // Map method to display name
  const getDisplayName = (method) => {
    switch (method) {
      case 'ship':
        return 'Ship';
      case 'mail':
        return 'Mail';
      case 'store_pickup':
        return 'Store Pickup';
      case 'virtual':
        return 'Virtual Email';
      default:
        return 'Unknown Method';
    }
  };

  // Apply Coupon Handler
  const handleCouponAction = async () => {
    if (isCouponApplied) {
      // Cancel the applied coupon
      setCouponAmount(0);
      setCouponCode('');
      setCouponError('');
      setIsCouponApplied(false);
    } else {
      // Apply the coupon
      if (!couponCode) {
        setCouponError('Please enter a coupon code.');
        return;
      }

      setCouponError('');
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}api/ecommerce/v1/apply-coupon`, {
          couponCode,
          cartItems
        });

        if (response.data.status) {
          setCouponAmount(response.data.data.promo_code_amount);
          setIsCouponApplied(true); // Set state to indicate coupon is applied
          setCouponError('');
        } else {
          setCouponError('Invalid coupon code or not applicable.');
        }
      } catch (error) {
        setCouponError('Error applying coupon code.');
        console.error('Error applying coupon:', error);
      }
    }
  };

  // Handle form submission and Stripe checkout
  const handleCheckout = async (event) => {
    event.preventDefault();

    if (selectedOption === 'ship' && (!address || !postalCode)) {
      setValidationError('Address and Postal Code are required for shipment.');
      return;
    }
    if (selectedOption === 'store_pickup' && (!store || !pickupDate)) {
      setValidationError('Store selection and Pickup Date are required for store pickup.');
      return;
    }
    if (selectedOption === 'virtual' && !email) {
      setValidationError('Recipient email is required for virtual shipping.');
      return;
    }

    if (selectedOption === '') {
      setValidationError('Please choose and add your shipping information');
      return;
    }

    setValidationError('');

    if (!stripe || !elements) {
      return;
    }

    try {
      const discountTotal = cartItems.reduce((acc, item) => acc + (item.originalPrice - item.price) * item.quantity, 0);

      // Create a single discount data object for the entire cart
      const discountData = {
        discountTotal: parseFloat(discountTotal).toFixed(2),
        stripeDiscountId: cartItems[0]?.stripeDiscountId || null, // Assume same discount ID for all items
        discountType: 'total',
      };

      const { data: { sessionId } } = await axios.post(
        `${process.env.REACT_APP_API_URL}api/ecommerce/v1/create-checkout-session`,
        {
          cartItems,
          discountData,
          couponData: { couponCode: couponCode, couponAmount: couponAmount },
          shippingMethod: { id: shippingId, method: selectedOption, cost: shippingCost, email, address, postalCode, store, pickupDate },
          // discountData: {stripeDiscountId: }
        }
      );

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe Checkout error:", error);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const handleShippingChange = (event) => {
    const selectedMethod = event.target.value;
    const method = shippingMethods.find((method) => method.method === selectedMethod);
    
    if (method) {
      setShippingCost(method.cost);
      setSelectedOption(method.method);
      setShippingId(method.id);
    } else {
      setShippingCost(0);
      setShippingId(null);
      setSelectedOption(selectedMethod);
    }
    console.log(shippingCost + selectedOption)
  };

  const handleRegionChange = (event) => {
    const region = event.target.value;
    setSelectedRegion(region);

    if (selectedOption === 'ship') {
      const methodCost = regions.find(
        (method) => method.region === region
      );
      console.log(regions)
      console.log(methodCost)
      setShippingCost(methodCost ? methodCost.cost : 0);
      setShippingId(methodCost.id);
    }
  };
  

  return (
    <form onSubmit={handleCheckout} className='w-100'>

      <div className="mt-3 mb-3">
        <span
          role="button"
          className="text-primary cursor-pointer"
          onClick={() => {
            if (showCoupon && isCouponApplied) {
              setCouponCode('');
              setCouponAmount(0);
              setIsCouponApplied(false);
            }
            setShowCoupon(!showCoupon);
          }}
          style={{ textDecoration: 'underline' }}
        >
          {showCoupon ? 'No, I don’t have a coupon' : 'Do you have a coupon?'}
        </span>
      </div>

      {/* Conditional Rendering of Coupon Section */}
      {showCoupon && (
        <div className="border rounded p-4 mb-4 mt-3">
          <h4 className="fw-medium fs-4 mb-3">Apply Coupon</h4>
          <div className="input-group">
            <input
              type="text"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className="form-control"
              placeholder="Enter coupon code"
            />
            <button type="button" className="btn btn-outline-primary" onClick={handleCouponAction}>
              {isCouponApplied ? 'Cancel' : 'Apply'}
            </button>
          </div>
          {couponError && (
            <div className="alert alert-danger mt-2">
              {couponError}
            </div>
          )}
        </div>
      )}


      <div className="border rounded p-4 mb-4">
        <h4 className="fw-medium fs-4 mb-4">Shipping Method</h4>
        <div className="d-flex flex-column justify-content-start gap-3">
          {shippingMethods.map((method) => (
            <div key={method.method} className="form-check">
              <input type="radio" id={method.method} name="shippingMethod" value={method.method} className="form-check-input" checked={selectedOption === method.method} onChange={handleShippingChange} />
              <label className="form-check-label fs-5" htmlFor={method.method}>{method.title}</label>
            </div>
          ))}
          <input type="radio" id="ship" name="shippingMethod" value='ship' className="form-check-input" checked={selectedOption === 'ship'} onChange={handleShippingChange} />
          <label className="form-check-label fs-5" htmlFor="ship">Shipping</label>
        </div>

        {/* Show email input if Virtual Email is selected */}
        {selectedOption === 'virtual' && (
          <div className="mt-3">
            <label className="fs-5">Recipient Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Enter your email"
            />
          </div>
        )}

        {/* Show address and postal code inputs if Shipment method is selected */}
        {selectedOption === 'ship' && (

          <div className="mt-3">
            <label className="fs-5">Select Region:</label>
            <select value={selectedRegion} onChange={handleRegionChange} className="form-select">
              <option value="">Select a region</option>
              {regions.map((region) => (
                <option key={region.region} value={region.region}>
                  {region.region} ( {region.shippingType == 'local' ? 'Singapore Only' : 'Overseas'} )
                </option>
              ))}
            </select>
            
            <label className="fs-5 mt-3">Address:</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="form-control"
              placeholder="Enter shipping address"
            />
            <label className="mt-3 fs-5">Postal Code:</label>
            <input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              className="form-control"
              placeholder="Enter postal code"
            />
          </div>
        )}

        {/* Show store and date inputs if Store Pickup is selected */}
        {selectedOption === 'store_pickup' && (
          <div className="mt-3">
            <label className="fs-5">Select Store:</label>
            <select
              value={store}
              onChange={(e) => setStore(e.target.value)}
              className="form-control"
            >
              <option value="">Select Store</option>
              <option value="SmartStickies">SmartStickies</option>
            </select>

            <label className="mt-3 fs-5">Pickup Date:</label>
            <input
              type="date"
              value={pickupDate}
              onChange={(e) => setPickupDate(e.target.value)}
              className="form-control"
            />
          </div>
        )}

        {validationError && (
          <div className="alert alert-danger mt-2">
            {validationError}
          </div>
        )}
      </div>

      {/* Coupon Discount */}
      {couponAmount > 0 && (
        <div className='d-flex justify-content-between'>
          <p className='fs-5'>Coupon Discount:</p>
          <p className='fs-5'>- ${parseFloat(couponAmount).toFixed(2)}</p>
        </div>
      )}

      {/* Shipping Fee Show */}
      <div className='d-flex justify-content-between'>
        <p className='fs-5'>Shipping Fee:</p>
        <p className='fs-5'>+ ${parseFloat(shippingCost).toFixed(2)}</p>
      </div>

      {/* Show total price */}
      <div className='d-flex justify-content-between'>
        <p className='fs-5 fw-bold'>Total Price:</p>
        <p className='fs-5 fw-bold'>${parseFloat(totalPrice).toFixed(2)}</p>
      </div>

      {/* Stripe CardElement for payment */}
      <CardElement />
      <button type="submit" disabled={!stripe || !elements} className='bg-black text-white btn w-100 mt-2'>
        Pay with Stripe
      </button>
    </form>
  );
};

export default CheckoutForm;
