import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import Api from "../api/api";
import apiUtil from '../api/apiUtil';

const SPFooter = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    callReadApi();
  }, []);

  const callReadApi = useCallback(() => {
    
    let param = {
      type: "footer",
    };
    

    setIsLoading(true);
    const getData = Api.read('tab', param );

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = data.data ? data.data : [];
        setTabs(dataArr);
        setIsLoading(false);
      }, (error, type) => {
        setTabs([]);
        setIsLoading(false);
      });
    });
  }, []);

  const handleNavigation = (name, id) => {

    if (name = 'terms of use') {
      navigate('/pages/terms-and-conditions', { state: { id } });
    } else {
      navigate(`/pages/${name.toLowerCase()}`, { state: { id } });
    }
  };

  return (
    <div className='fotBg'>
      <footer className="footer p-10 text-xl text-base-content">
        {tabs.map((section) => (
          <div key={section.id}>
            <span className="footer-title text-white">{section.name}</span>
            {section.tabs && section.tabs.length > 0 ? (
              section.tabs.map((tab) => (
                <span
                  key={tab.id}
                  onClick={() => handleNavigation(tab.name, tab.id)}
                  className="link link-hover text-white cursor-pointer"
                >
                  {tab.name}
                </span>
              ))
            ) : (
              <p className='text-white'>No {section.name} available</p>
            )}
          </div>
        ))}
      </footer>
      <div className='px-4'>
        <hr className='text-white' />
      </div>
      <footer className="footer px-4 pb-4 text-xl text-base-content">
      {/* <span
                  onClick={()=> navigate(`/pages/contact-us`)}
                  className="link link-hover text-white cursor-pointer"
                >
                  Contact Us
                </span> */}
      </footer>
      <footer className="footer px-10 py-4">
        <div className="items-center grid-flow-col">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
            <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
          </svg>
          <p className='font-bold'>BUILT BY SMARTSTICKIES. <br /></p>
        </div>
        <div className="md:place-self-center md:justify-self-end">
          <div className="grid grid-flow-col gap-4">
            <a href="https://www.instagram.com/smartstickies/">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.33 3.608 1.305.975.975 1.243 2.242 1.305 3.608.058 1.266.069 1.646.069 4.85s-.012 3.584-.069 4.85c-.062 1.366-.33 2.633-1.305 3.608-.975.975-2.242 1.243-3.608 1.305-1.266.058-1.646.069-4.85.069s-3.584-.012-4.85-.069c-1.366-.062-2.633-.33-3.608-1.305-.975-.975-1.243-2.242-1.305-3.608-.058-1.266-.069-1.646-.069-4.85s.012-3.584.069-4.85c.062-1.366.33-2.633 1.305-3.608.975-.975 2.242-1.243 3.608-1.305 1.266-.058 1.646-.069 4.85-.069zm0-2.163c-3.257 0-3.667.012-4.947.07-1.562.072-2.957.335-4.034 1.412-1.077 1.077-1.34 2.472-1.412 4.034-.058 1.28-.07 1.69-.07 4.947s.012 3.667.07 4.947c.072 1.562.335 2.957 1.412 4.034 1.077 1.077 2.472 1.34 4.034 1.412 1.28.058 1.69.07 4.947.07s3.667-.012 4.947-.07c1.562-.072 2.957-.335 4.034-1.412 1.077-1.077 1.34-2.472 1.412-4.034.058-1.28.07-1.69.07-4.947s-.012-3.667-.07-4.947c-.072-1.562-.335-2.957-1.412-4.034-1.077-1.077-2.472-1.34-4.034-1.412-1.28-.058-1.69-.07-4.947-.07zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.162 6.162 6.162 6.162-2.759 6.162-6.162-2.759-6.162-6.162-6.162zm0 10.324c-2.295 0-4.162-1.867-4.162-4.162s1.867-4.162 4.162-4.162 4.162 1.867 4.162 4.162-1.867 4.162-4.162 4.162zm6.406-11.845c-.796 0-1.441-.645-1.441-1.441s.645-1.441 1.441-1.441 1.441.645 1.441 1.441-.645 1.441-1.441 1.441z"/>
            </svg>
            </a>
            <a href="https://www.youtube.com/@smartstickies">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/smartstickies">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-9h3v9zm-1.5-10.268c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 10.268h-3v-4.5c0-1.086-.914-2-2-2s-2 .914-2 2v4.5h-3v-9h3v1.434c.744-1.102 2.021-1.434 3.5-1.434 2.485 0 4.5 2.015 4.5 4.5v4.5z"/>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SPFooter;
