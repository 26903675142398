// src/components/StripeCheckoutButton.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import CheckoutForm from './CheckoutForm';

const StripeCheckoutButton = ({ cartItems }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const { data: { stripeAccount } } = await axios.post(
          `${process.env.REACT_APP_API_URL}api/ecommerce/v1/create-checkout-session`,
          { cartItems }
        );

        const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_URL, { stripeAccount });
        setStripePromise(stripe);
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeStripe();
  }, [cartItems]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm cartItems={cartItems} />
    </Elements>
  );
};

export default StripeCheckoutButton;
