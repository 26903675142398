import React, { useState } from 'react';
import { FaShoppingBag } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from "../assets/cara.png";
import "../styles/Navbar.css";
import { Link } from 'react-router-dom';

const MobileNav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Toggle the menu visibility
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='mobNavMainPrant'>
            <div className="navbar">
                <div className='d-flex px-4 justify-content-between align-items-center w-100'>
                    {/* Logo */}
                    <Link to="/">
                        <img src={logo} className="w-32" alt="Logo" />
                    </Link>

                    {/* Hamburger Icon to toggle the menu */}
                    <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="text-xl" />
                    </button>
                </div>
            </div>

            {/* Collapsible Menu */}
            <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul className="menuNav flex flex-col p-4">
                    <li className="dropdown nav-link">
                        <Link to="/" onClick={toggleMenu}>BEST SELLERS</Link>
                    </li>
                    <li className="dropdown nav-link">
                        <Link to="/customize-nfc" onClick={toggleMenu}>CUSTOMIZE NFC</Link>
                    </li>
                    <li className="dropdown nav-link">
                        <Link to="/search-order" onClick={toggleMenu}>View Order</Link>
                    </li>
                    <li className="dropdown nav-link">
                        <Link to="/pages/contact-us" onClick={toggleMenu}>Contact Us</Link>
                    </li>
                    <hr />
                        <li className="dropdown nav-link">
                            {/* Cart Icon */}
                            <Link to="/cart">
                                <FaShoppingBag className='text-xl' />
                            </Link>
                        </li>
                    <hr />
                </ul>
            </div>
        </div>
    );
};

export default MobileNav;
