import React, {useState, useEffect, useRef, useCallback, useContext} from 'react'
import { store } from '../productsStore/Store'
import "../styles/BestSellers.css";
import HoverImage from 'react-hover-image/build';
import { Link } from 'react-router-dom';
import Api from "../api/api"
import apiUtil from '../api/apiUtil';
import Loading from '../assets/loading.gif'
import { FaShoppingBag } from "react-icons/fa";

const BestSellers = () => {

  const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        callReadApi();
      }, [])
    
      const callReadApi = useCallback(() => {
    
        let param ='';
    
         param = {
          };
        
          setIsLoading(true);
          const getData = Api.read('products', param)
          
          Promise.all([getData]).then( (results) => {
              apiUtil.parseResult(results[0], (data) => {
                const dataArr = (data.data && data.data.length > 0) ? data.data : []
                const transformedData = dataArr && dataArr.map(product => ({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    type: 'bestSeller', // example logic for type
                    primaryImage: product.feature_image_url,
                    hoverImg: product?.images?.length > 0 ? product.images[0].file_url : product.feature_image_url,
                    gallery_images: product.images,
                    description: product.description,
                    quantity: product.quantity,
                  }));

                setData(transformedData);
                setIsLoading(false);
    
              }, (error, type) => {
                setData([]);
                setIsLoading(false);
              });
          });
      }, [])

  if (isLoading) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{minHeight: '400px'}}>
      <img src={Loading} className="img-fluid" style={{objectFit: 'cover', width : '300px', height: '300px'}} />
    </div>;
  }
  
  return (
    <div className='row p-4'>
      {/* {data.map((item) => {
        if (item.type == "bestSeller")
          return (
            <Link to={`/${item.id}`} key={item.id} className="col-12 col-lg-3">  
            <div key={item.id} className='d-flex flex-column justify-content-center align-items-center'>
              <HoverImage src={item.primaryImage} hoverSrc={item.hoverImg} className="img-fluid border rounded rounded-xl mb-6" 
              style={{objectFit: 'cover', width: '200px', height: '200px'}}/>
              <p className='bestSellerName text-center mb-2'> {item.name} </p>
              <p className=' font-normal text-center'> ${parseFloat(item.price).toFixed(2)} </p>
            </div>
            </Link>
          )
      })} */}

      {data
        .filter(item => item.type === "bestSeller") 
        .sort((a, b) => b.id - a.id)                
        .slice(0, 4)                            
        .map(item => (
          <Link to={`/${item.id}`} key={item.id} className="col-12 col-lg-3">  
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <HoverImage 
                src={item.primaryImage} 
                hoverSrc={item.hoverImg} 
                className="img-fluid border rounded rounded-xl mb-6" 
                style={{objectFit: 'cover', width: '200px', height: '200px'}}
              />
              <p className='bestSellerName text-center mb-2'> {item.name} </p>
              <p className='font-normal text-center'> ${parseFloat(item.price).toFixed(2)} </p>
            </div>
          </Link>
        ))}
        <hr />
        <Link to={`/catalog`} className="px-4 col-12 col-lg-12 d-flex justify-content-end align-items-center">  
          <a className='fs-6 text-primary d-flex align-items-center'><FaShoppingBag /> &nbsp; &nbsp; Shop all items</a>
        </Link>
    </div>
  )
}

export default BestSellers;
