import React, {useState, useEffect, useRef, useCallback, useContext} from 'react'
import { store } from "../productsStore/Store";
import OurBestSellers from './OurBestSellers';
import Api from "../api/api"
import apiUtil from '../api/apiUtil';
import { Link } from 'react-router-dom';
import { FaShoppingBag } from "react-icons/fa";

const Catalog = () => {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        callReadApi();
      }, [])
    
      const callReadApi = useCallback(() => {
    
        let param ='';
    
         param = {
          };
        
          setIsLoading(true);
          const getData = Api.read('products', param)
          
          Promise.all([getData]).then( (results) => {
              apiUtil.parseResult(results[0], (data) => {
                const dataArr = (data.data && data.data.length > 0) ? data.data : []
                const transformedData = dataArr && dataArr.map(product => ({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    type: 'ourBestSellers', // example logic for type
                    primaryImage: product.feature_image_url,
                    hoverImg: product?.images?.length > 0 ? product.images[0].file_url : product.feature_image_url,
                    gallery_images: product.images,
                    description: product.description,
                    quantity: product.quantity,
                  }));

                setData(transformedData);
                setIsLoading(false);
    
                console.log('data ds', dataArr, transformedData)
              }, (error, type) => {
                setData([]);
                setIsLoading(false);
              });
          });
      }, [])

    return (
        <div className='container-fluid mb-4'>
            <div className='row w-100 mt-4 p-0 m-0'>
                {data && data.map(((item) => {
                        return (
                            <OurBestSellers
                                key={item.id}
                                id={item.id}
                                title={item.name}
                                price={item.price}
                                image={item.primaryImage}
                            />
                        )
                }))}
            </div>
        </div>
    )
}

export default Catalog