import React from 'react'
import { store } from '../productsStore/Store'
import "../styles/OurBestSellers.css"
import { useDispatch } from 'react-redux'
import { cartActions } from '../redux-state/CartState'

import { Link } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'


const OurBestSellers = (props) => {

    const { title, price, stripeDiscountId, originalPrice, id, image } = props;

    const dispacth = useDispatch();

    const addItemToCartHandler = (e) => {

        dispacth(
            cartActions.addItemToCart({
                id,
                price,
                stripeDiscountId,
                originalPrice,
                title,
                image,
            })
        )
    }

    const toast = useToast();




    return (
        <div className='col-12 col-lg-3 d-flex justify-content-center align-items-center mb-4'>
            <div key={id}>

                <div className="card shadow-xl">

                    <Link to={`/${id}`}>
                        <figure className="px-10 pt-10">
                            <img src={image} alt="Shoes" className="rounded-xl" style={{objectFit: 'cover', width: '100%', height: '250px'}} />
                        </figure>

                    </Link>
                    <div className="card-body items-center text-center">
                        <h2 className="card-title mb-1 font-bold text-xl">{title}</h2>
                        {/* <h2 className=" text-xl mb-2 fof">${parseFloat(price).toFixed(2)}</h2> */}

                        {originalPrice && originalPrice !== price ? (
                            <>
                                <h2 className="text-lg text-muted mb-2">
                                <del>${parseFloat(originalPrice).toFixed(2)}</del>
                                </h2>
                                <h2 className="text-xl mb-2 text-success">${parseFloat(price).toFixed(2)}</h2>
                            </>
                        ) : (
                            <h2 className="text-xl mb-2">${parseFloat(price).toFixed(2)}</h2>
                        )}


                        <div className="card-actions" onClick={() =>
                            toast({
                                title: '',
                                description: "Successfully Added",
                                status: 'success',
                                duration: 1500,
                                isClosable: true,
                            })
                        }>
                            <button className="btn btn-primary" onClick={addItemToCartHandler}>Buy Now</button>
                        </div>

                    </div>

                </div>

            </div>



        </div>
    )
}

export default OurBestSellers
